import { useEffect, useState } from "react"
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import "./login.css"

function Login(props) {
    const [getLogin, setLogin] = useState({})
    const [getErr, setErr] = useState("")
    const loginbtn = async () => {
        try {

            const resp = await fetch(`${process.env.REACT_APP_URL}/api/login`, {
                method: 'POST',
                body: JSON.stringify({
                    ...getLogin
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (resp.status === 200) {
                if (dataset.status === true) {
                    const cookies = new Cookies()
                    cookies.set('user', dataset.data.token, { path: '/' })
                    props.setPage("home")
                } else {
                    setErr(dataset.msg)
                    setTimeout(() => { setErr("") }, 3000);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        props.check_cookie()
    }, [])
    return (
        <>
            <div className="othermain">
                <div className="maindiv">
                    <div className="bottom">
                        <text>Login</text>
                    </div>
                        <label >Email:</label>
                        <input name="email" placeholder="Enter your Email here" className="mt10 mb10" onChange={(e) => { setLogin({ ...getLogin, [e.target.name]: e.target.value }) }} />
                        <label >Password:</label>
                        <input type="password" name="password" placeholder="Enter you Password here" className="mt10" onChange={(e) => { setLogin({ ...getLogin, [e.target.name]: e.target.value }) }} />
                        <p className="errormsg">{getErr}</p>
                        <a href="javascript:;" className="mt10 link" onClick={() => { props.setPage("register") }}>register</a>
                        <button onClick={() => { loginbtn() }} className="mt20 mb20" >LOGIN</button>
                </div>
            </div>
        </>
    )
}

export default Login