import Navbar from "../nav"

function Refund(props) {
    return (
        <>
            <Navbar setPage={props.setPage} />
            <div className="heading">
                <p>Refund and return</p>

                <p>At HackForge Labs, we strive to provide our customers with high-quality Software as a Service (SaaS) and Platform as a Service (PaaS) solutions that meet their needs and expectations. However, we understand that there may be instances where a refund or return is necessary. This policy outlines our guidelines for refund and return requests to ensure a fair and transparent process for our valued customers.</p>

                <p>Refund Policy:</p>
                <p>
                    Eligibility: Our refund policy applies to paid subscriptions and licenses for our SaaS and PaaS products. To be eligible for a refund, the customer must request it within 30 days of the initial purchase date. After this period, refunds will not be issued.
                </p>
                <p>Grounds for Refunds: We offer refunds in the following situations:</p>
                <p>
                    a. Technical Issues: If our product fails to function as described or encounters significant technical issues that prevent its proper use within 30 days of purchase, we will issue a full refund.<br />
                    b. Unmet Expectations: If the customer can demonstrate that our product does not meet the features or functionalities advertised on our website or documentation, a refund may be granted.<br />
                    c. Duplicate Purchases: In the event of accidental duplicate purchases, we will refund the duplicate charge upon verification.
                </p>
                <p>
                    Refund Process: To request a refund, customers must contact our support team via email or our designated customer support channel. The request should include the order details, purchase date, and a brief explanation of the reason for the refund. Our support team will review the request and respond within a reasonable timeframe, typically within five business days.
                </p>
                <p>Return Policy:</p>
                <p>
                    Non-Tangible Nature: As a provider of SaaS and PaaS solutions, our products are non-tangible and delivered electronically. Therefore, physical returns are not applicable. However, we will diligently address any technical issues or discrepancies that fall under the grounds for refunds mentioned above.
                </p>
                <p>Support and Assistance: If customers encounter any difficulties or need assistance with our products, our dedicated support team is available to provide guidance and resolve any issues. We encourage customers to reach out to our support channels for prompt assistance before considering a refund.</p>
                <p>
                    Exclusions: The following scenarios are not eligible for refunds or returns:
                </p>
                <p>
                    a. Change of Mind: If the customer simply changes their mind or decides the product is not suitable for their needs, a refund will not be granted.<br />
                    b. Third-Party Integration Issues: We are not responsible for compatibility issues or limitations resulting from third-party integrations or external factors beyond our control.
                </p>
                <p>
                    We value the satisfaction of our customers and aim to address any concerns promptly and effectively. By providing clear guidelines for refund and return requests, we ensure transparency and fairness in our business practices. Should you have any questions or require further clarification, please don't hesitate to contact our support team, who will be happy to assist you.
                </p>

            </div>
        </>
    )
}

export default Refund