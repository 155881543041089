import Navbar from "../nav"
import "./scc.css"


function Privacy(props) {
    return (
        <>
            <Navbar setPage={props.setPage} />
            <div className="heading">
                <p >Privacy Policy</p>

                <div className="simpletext">
                    <p >At HackForge Labs, we are committed to protecting the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information collected through our Software-as-a-Service (SaaS) and Platform-as-a-Service (PaaS) offerings. By accessing or using our services, you agree to the terms and conditions outlined in this Privacy Policy.</p>
                </div>

                <div>
                    <p>Information We Collect:</p>
                </div>

                <div>
                    <p>We may collect personal information from our users, including but not limited to:<br />
                        Contact Information: Name, email address, phone number, and company details.<br />
                        Account Credentials: Username, password, and other authentication information.<br />
                        Usage Data: Information about your interactions with our services, such as IP address, browser type, pages visited, and referring URL.<br />
                        Payment Information: If applicable, we may collect payment details necessary for processing transactions.</p>
                    <p>How We Use Your Information:</p>
                    <p>We may use the collected information for various purposes, including:<br />
                        Providing and improving our services, including customer support and troubleshooting.<br />
                        Personalizing and tailoring the user experience.<br />
                        Communicating with you about our services, updates, and promotional offers.<br />
                        Conducting research and analysis to enhance our offerings.<br />
                        Enforcing our terms and policies and preventing fraud or illegal activities.<br />
                        Information Sharing and Disclosure:<br />
                        We may share your personal information in the following circumstances:<br />
                        With third-party service providers who assist us in delivering our services (e.g., hosting providers, payment processors).<br />
                        With your consent or at your direction.<br />
                        In response to a legal request or to comply with applicable laws, regulations, or court orders.<br />
                        In connection with a merger, acquisition, or sale of all or a portion of our business.<br />
                    </p>
                    <p>Data Security:</p>
                    <p>
                        We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </p>
                    <p>Data Retention:</p>
                    <p>
                        We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. We will securely dispose of personal information when it is no longer needed.
                    </p>
                    <p>Your Rights and Choices:</p>
                    <p>
                        You have certain rights regarding your personal information, subject to local data protection laws. These rights may include the right to access, rectify, restrict or delete your personal information. You can exercise these rights by contacting us using the information provided below.
                    </p>
                    <p>Third-Party Links and Services:</p>
                    <p>
                        Our services may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to review the privacy policies of those third parties before providing any personal information.
                    </p>
                    <p>International Data Transfers:</p>
                    <p>
                        We may transfer your personal information to servers located in countries outside of your jurisdiction. By using our services, you consent to the transfer of your personal information to such locations.
                    </p>
                    <p>Updates to this Privacy Policy:</p>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other reasonable means. Your continued use of our services after the effective date of the updated Privacy Policy constitutes your acceptance of the revised terms.
                    </p>
                    <p>Contact Us:</p>
                    <p>
                        If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at info@hackforgelabs.com.<br />

                        In the event of any inconsistencies or conflicts between this Privacy Policy and any other related agreements or terms, this Privacy Policy shall govern.
                    </p>
                </div>

            </div>



        </>
    )
}

export default Privacy