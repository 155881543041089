import { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import Cookies from "universal-cookie";
import "./profile.css"


function Profile(props) {
    const [getProfile, setProfile] = useState({});
    const [getPass, setPass] = useState("");
    const [getMsg, setMsg] = useState("");
    const cookie = new Cookies()
    const getprofile = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/profile`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setProfile(dataset.data)
            } else {
                setProfile({})
            }
        } catch (error) {
            console.log(error)
        }
    }
    const changepass = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/changepass`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    password: getPass
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setMsg("Password changed")
                setTimeout(() => { setMsg("") }, 3000);
            } else {
                setMsg("Error: Password not changed")
                setTimeout(() => { setMsg("") }, 3000);
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        props.check_cookie()
        getprofile()
    }, [])
    return (
        <>
            <Navbar setPage={props.setPage}/>
            <div className="parentdiv">
                <div className="childdiv">
                    <h3>Profile</h3>
                    <p style={{ "font-weight": "900" }}>Name:</p>
                    <p>{getProfile.name}</p>
                    <p style={{ "font-weight": "900" }}>Email:</p>
                    <p>{getProfile.email}</p>
                    <p style={{ "font-weight": "900" }}>Phone:</p>
                    <p>{getProfile.phone}</p>
                    <p style={{ "font-weight": "900" }}>Password:</p>
                    <input onChange={(e) => { setPass(e.target.value) }} placeholder="Enter your new password" />
                    <button onClick={()=>{changepass()}} className="mp10">Change Password</button>
                    <p>{getMsg}</p>
                </div>
            </div>
        </>
    )
}

export default Profile