import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import "./navbar.css"

function Navbar(props) {
  const cookie = new Cookies()
  const navigate = useNavigate()
  const logout = async()=>{
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/logout`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if(dataset.status === true){
                cookie.remove('user', {path: '/'})
                props.setPage("login")
            }else{
                alert("error")
                console.log("error")
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="navbar">
                <ul className="navlist">
                    <li><a href="javascript:;" onClick={()=>{props.setPage("home")}}>Home</a></li>
                    <li><a href="javascript:;" onClick={()=>{props.setPage("subscription")}}>Subscription</a></li>
                    <li><a href="javascript:;" onClick={()=>{props.setPage("profile")}}>Profile</a></li>
                    <li><a href="javascript:;" onClick={()=>{logout();}}>Logout</a></li>
                </ul>
            </div>
        </>
    )
}

export default Navbar