import { useEffect, useState } from "react"
import Navbar from "../navbar/navbar"
import Cookies from "universal-cookie";
import "./sub.css"

function Sub(props) {
    const [getActive, setActive] = useState()
    const [getCount, setCount] = useState(0)
    const [getEnc, setEnc] = useState("")
    const cookie = new Cookies()
    const getexpire = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/expire`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setActive(dataset.data.time)
            } else {
                setActive()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const encData = async (numstate) => {
        try {
            var numb
            if (numstate === true) {
                setCount(getCount + 1)
                numb = getCount + 1
            }
            if (numstate === false && getCount > 0) {
                setCount(getCount - 1)
                numb = getCount - 1
            }
            // const resp = await fetch(`${process.env.REACT_APP_URL}/api/sub`, {
            //     method: 'POST',
            //     body: JSON.stringify({
            //         token: cookie.get('user'),
            //         month: numb,
            //         state: false
            //     }),
            //     headers: {
            //         'Content-type': 'application/json; charset=UTF-8',
            //     }
            // })
            // const dataset = await resp.json()
            // setEnc(dataset.enc)
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async () => {
        const resp = await fetch(`${process.env.REACT_APP_URL}/api/sub`, {
            method: 'POST',
            body: JSON.stringify({
                token: cookie.get('user'),
                month: getCount,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const dataset = await resp.json()
        setEnc(dataset.enc)

    }
    const Buybtn = () => {
        if (getCount > 0) {
            return (
                <input type="submit" onClick={(e) => { e.preventDefault(); handleClick() }}></input>
            )
        }
    }

    const gettime = () => {
        var date = new Date()
        const currenttime = date.getTime()
        const diff = getActive - currenttime
        const seconds = parseInt(diff / 1000)
        const minutes = parseInt(seconds / 60)
        const hours = parseInt(minutes / 60)
        const days = parseInt(hours / 24)
        const finalhours = hours - (days * 24)
        const finalminutes = minutes - (hours * 60)
        return `${Math.max(0, days)} days ${Math.max(0, finalhours)} hours ${Math.max(0, finalminutes)} minutes`
    }
    useEffect(() => {
        props.check_cookie()
        getexpire()
    }, [])
    useEffect(() => {
        if (getEnc) {
            document.getElementById("myForm").submit();
        }
    }, [getEnc])
    return (
        <>
            <Navbar setPage={props.setPage} />
            <div className="parentdiv">
                <h3>Active till:</h3>
                <p>{gettime()}</p>
                <div className="childdiv">
                    <h3>Purchase Subscription:</h3>
                    <p>Months: &emsp;
                        <button onClick={(e) => { encData(false) }}>-</button>{getCount}&ensp;<button onClick={(e) => { encData(true) }}>+</button>
                    </p>
                    <form id="myForm" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                        <input type="hidden" id="encRequest" name="encRequest" value={getEnc}></input>
                        <input type="hidden" name="access_code" id="access_code" value={process.env.REACT_APP_ACCESSCODE}></input>
                        <Buybtn />
                    </form>
                </div>
            </div >
        </>
    )
}

export default Sub