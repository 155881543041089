import Navbar from "../nav"

function Tc(props) {
    return (
        <>
            <Navbar setPage={props.setPage} />
            <div className="heading">
                <p>Terms and condition <br />
                    Terms and Conditions of HackForge Labs for SaaS and PaaS Services</p>

                <p>Effective Date: 01/04/23</p>
                <p>
                    Welcome to HackForge Labs! These terms and conditions ("Terms") govern your use of our Software as a Service (SaaS) and Platform as a Service (PaaS) offerings. By accessing or using our services, you agree to comply with and be bound by these Terms. Please read them carefully before using any of our SaaS or PaaS services.
                </p>
                <p>Acceptance of Terms</p>
                <p>
                    By accessing or using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, please do not use our services.
                </p>
                <p>Definitions</p>
                <p>
                    (a) "HackForge Labs" refers to the company providing the SaaS and PaaS services.<br />
                    (b) "Services" refer to the SaaS and PaaS offerings provided by HackForge Labs.<br />
                    (c) "User" or "You" refers to any individual or entity accessing or using the Services.
                </p>
                <p>Use of Services</p>
                <p>
                    (a) You may only use the Services for lawful purposes and in accordance with these Terms.<br />
                    (b) You agree not to engage in any activity that may disrupt, interfere with, or compromise the integrity or security of the Services.<br />
                    (c) You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                </p>
                <p>Intellectual Property Rights</p>
                <p>
                    (a) HackForge Labs retains all intellectual property rights to the Services, including but not limited to software, logos, trademarks, and any content provided.<br />
                    (b) You may not use HackForge Labs' intellectual property without prior written consent.
                </p>
                <p>Data Protection and Privacy</p>
                <p>
                    (a) HackForge Labs is committed to protecting your privacy and personal data. Our Privacy Policy explains how we collect, use, and disclose your information.<br />
                    (b) By using the Services, you consent to the collection, use, and disclosure of your information as described in our Privacy Policy.
                </p>
                <p>Payment and Fees</p>
                <p>
                    (a) If the use of the Services requires payment, you agree to pay all applicable fees as stated on the HackForge Labs website or as otherwise agreed upon.<br />
                    (b) All fees are non-refundable, and you are responsible for any taxes or other additional charges related to your use of the Services.
                </p>
                <p>Termination</p>
                <p>
                    (a) HackForge Labs may, at its sole discretion, suspend or terminate your access to the Services at any time and for any reason, without notice.<br />
                    (b) Upon termination, your right to access and use the Services will cease immediately.
                </p>
                <p>Limitation of Liability</p>
                <p>
                    (a) HackForge Labs shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of the Services. <br />
                    (b) HackForge Labs' total liability, whether in contract, tort, or otherwise, shall not exceed the fees paid by you for the Services during the six (6) months preceding the claim.
                </p>
                <p>Indemnification</p>
                <p>
                    You agree to indemnify, defend, and hold HackForge Labs harmless from and against any claims, liabilities, damages, losses, costs, and expenses arising out of or in connection with your use of the Services or any violation of these Terms.
                </p>
                <p>Governing Law and Jurisdiction</p>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of jurisdiction.
                </p>
                <p>Amendments</p>
                <p>
                    HackForge Labs reserves the right to modify or update these Terms at any time without prior notice. By continuing to use the Services, you agree to be bound by the modified Terms.
                </p>
                <p>Entire Agreement</p>
                <p>
                    These Terms constitute the entire agreement between you and HackForge Labs regarding the subject matter herein and supersede all prior or contemporaneous agreements, understandings, or representations.
                </p>
                <p>If you have any questions or concerns about these Terms, please contact us at info@hackforgelabs.com.</p>
                <p>
                    By using the Services, you acknowledge that you have read, understood, and agree to these Terms.
                </p>

            </div>


        </>
    )
}

export default Tc