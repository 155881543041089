import { useState } from "react"
import "./register.css"

function Register(props) {
    const [getInfo, setInfo] = useState({})
    const [getPassword, setPassword] = useState("")
    const [getErr, setErr] = useState("")
    const [getMatch, setMatch] = useState("")
    const register = async () => {

        console.log(`${process.env.REACT_APP_URL}/api/register`)

        const validate = RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        if (validate.test(getInfo.email)) {
            if (getInfo.card.length === 16) {
                if (getInfo.phone.length === 10) {


                    const resp = await fetch(`${process.env.REACT_APP_URL}/api/register`, {
                        method: 'POST',
                        body: JSON.stringify({
                            ...getInfo
                        }),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        }
                    })
                    const dataset = await resp.json()
                    if (dataset.status === true) {
                        props.setPage("login")
                    } else if (dataset.status === false) {
                        setErr(dataset.msg)
                        setTimeout(() => { setErr("") }, 3000);
                    } else {
                        console.log("error")
                    }
                } else {
                    setErr("Invalid phone no.")
                    setTimeout(() => { setErr("") }, 3000);
                }
            } else {
                setErr("Invalid card")
                setTimeout(() => { setErr("") }, 3000);
            }
        } else {
            setErr("Invalid Email")
            setTimeout(() => { setErr("") }, 3000);
        }
    }
    return (
        <>
            <div className="othermain">
                <div className="maindiv">
                    <div className="bottom">

                    <text>Register</text>
                    </div>
                    <label >Name:</label>
                    <input name="name" placeholder="Enter your Name here" className="mt10 mb10" onChange={(e) => { setInfo({ ...getInfo, [e.target.name]: e.target.value }) }} />
                    <label >Email:</label>
                    <input name="email" placeholder="Enter you Email here" className="mt10 mb10" onChange={(e) => { setInfo({ ...getInfo, [e.target.name]: e.target.value }) }} />
                    <label >Phone:</label>
                    <input name="phone" placeholder="Enter you Phone number here" className="mt10 mb10" onChange={(e) => { if (!isNaN(e.target.value) && e.target.value.length <= 10) { setInfo({ ...getInfo, [e.target.name]: e.target.value }) } else { e.target.value = getInfo[e.target.name] } }} />
                    <label >Card:</label>
                    <input name="card" placeholder="Enter you Card number here" className="mt10 mb10" onChange={(e) => { if (!isNaN(e.target.value) && e.target.value.length <= 16) { setInfo({ ...getInfo, [e.target.name]: e.target.value }) } else { e.target.value = getInfo[e.target.name] } }} />
                    <label >Password:</label>
                    <input type="password" name="password" placeholder="Enter you Password here" className="mt10 mb10" onChange={(e) => { setInfo({ ...getInfo, [e.target.name]: e.target.value }) }} />
                    <label >Confirm Password:</label>
                    <input type="password" name="cpassword" placeholder="Enter you Password here" className="mt10 mb10" onChange={(e) => { setPassword(e.target.value); if (getInfo.password === e.target.value) { setMatch("") } else { setMatch("Doesn't match") } }} />
                    <p className="errormsg">{getMatch}</p>
                    <p className="errormsg">{getErr}</p>
                    <a href="javascript:;" className="mt10 link" onClick={() => { props.setPage("login") }}>sign in {"(already signed up)"}</a>
                    <button onClick={() => { register() }} className="mt20 mb20" >REGISTER</button>
                </div>
            </div>
        </>
    )
}

export default Register