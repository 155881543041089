import desktop_logo from './resources/desktop_logoname.svg'
import desktop_login from './resources/desktop_login_btn.svg'


function Navbar(props) {
    return (

        <div className="topnav">
            <div className='logo'>
                <img className="responsive mt20 ml20" src={desktop_logo} />
            </div>
            <div className='links'>
                <a href="javascript:;" onClick={(e) => { props.setPage("landing") }}>Home</a>
                <a href="javascript:;" onClick={(e) => { props.setPage("contact") }}>Contact Us</a>
                <a href="javascript:;" onClick={(e) => { props.setPage("tc") }}>T&C</a>
                <a href="javascript:;" onClick={(e) => { props.setPage("privacy") }}>Privacy policy</a>
                <a href="javascript:;" onClick={(e) => { props.setPage("about") }}>About Us</a>
                <a href="javascript:;" onClick={(e) => { props.setPage("refund") }}>Refund</a>
                <div className='login' onClick={(e) => { props.setPage("login") }}>
                    <img src={desktop_login} />
                    <a href="javascript:;" className='btnlogin' >Login</a>
                </div>
            </div>
        </div>
    )
}


export default Navbar