// import { Routes, Route } from "react-router-dom";
import './App.css';
// import Login from './pages/login/login';
// import Register from './pages/register/register';
// import Home from './pages/home/home';
// import Sub from './pages/sub/sub';
// import Profile from './pages/profile/profile';
// import Contact from "./pages/contact/contact";
// import Cookies from "universal-cookie";
// import { useState } from "react";
import Mainpage from "./pages/main";

function App() {
  return (
    <>
      <Mainpage />
    </>
  );
}

export default App;
