import Navbar from "../nav"

function Contact(props) {
    return (
        <>
            <Navbar setPage={props.setPage} />
            <div className="heading">

                <p>Email:</p>
                <input></input>

                <p>phone:</p>
                <input></input>

                <p>Query:</p>
                <textarea rows={10}></textarea>
                <div>
                    <button onClick={(e)=>{alert("message sent!")}}>Submit</button>
                </div>


                <p>info@hackforgelabs.com</p>



            </div>
        </>
    )
}

export default Contact