import Navbar from "../nav"

function About(props){
    return(
        <>
        <Navbar setPage={props.setPage}/>
        <div className="heading">
            <p>About us</p>
            <p>HackForge Labs is a cutting-edge technology company specializing in Software-as-a-Service (SaaS) and Platform-as-a-Service (PaaS) solutions. With a passion for innovation and a commitment to excellence, we strive to empower businesses worldwide with advanced software tools and scalable platforms.</p>
            <p>At HackForge Labs, we understand that the digital landscape is constantly evolving, and businesses need agile and adaptable solutions to stay ahead of the curve. Our team of seasoned professionals combines expertise in software development, cloud computing, and data management to deliver state-of-the-art SaaS and PaaS products tailored to meet the unique needs of each client.</p>
            <p>Our SaaS offerings provide businesses with ready-to-use software applications accessible through the cloud. From customer relationship management (CRM) systems to project management tools and marketing automation platforms, our SaaS solutions enable organizations to streamline their operations, enhance productivity, and optimize efficiency. With a focus on user-friendly interfaces and robust functionality, our SaaS products are designed to seamlessly integrate into existing workflows, allowing businesses to achieve their goals more effectively.</p>
            <p>In addition to our SaaS offerings, HackForge Labs also specializes in developing PaaS solutions that empower businesses to build, deploy, and manage their applications in the cloud. Our PaaS platforms provide developers with a comprehensive set of tools and resources, including infrastructure, middleware, and development frameworks. By leveraging our PaaS solutions, businesses can accelerate their application development process, reduce infrastructure costs, and scale their applications effortlessly as their needs grow.</p>
            <p>What sets HackForge Labs apart is our commitment to innovation and continuous improvement. We invest heavily in research and development, staying at the forefront of emerging technologies and industry trends. This allows us to develop cutting-edge SaaS and PaaS solutions that not only meet the current demands of businesses but also anticipate future needs. Our team of experienced engineers and developers are constantly pushing boundaries, seeking new ways to enhance performance, security, and scalability in our products.</p>
            <p>At HackForge Labs, we firmly believe in the power of collaboration and partnership. We work closely with our clients, understanding their unique requirements and objectives to deliver customized SaaS and PaaS solutions that drive growth and success. Our agile development methodology ensures that we adapt to evolving needs, delivering solutions on time and within budget.</p>
            <p>Security and data privacy are paramount in today's digital landscape, and HackForge Labs places the utmost importance on protecting our clients' sensitive information. We adhere to stringent security protocols and industry best practices to ensure the confidentiality, integrity, and availability of data.</p>
            <p>In summary, HackForge Labs is a leading provider of SaaS and PaaS solutions, empowering businesses with innovative software tools and scalable platforms. With a focus on collaboration, security, and continuous improvement, we are dedicated to helping organizations harness the power of technology to drive growth and achieve their goals in the ever-evolving digital era.</p>
        </div>
        </>
    )
}

export default About