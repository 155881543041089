import desktop from './resources/desktop_view.svg'
import './landing.css'
import Navbar from './nav'


function Landing(props) {
    return (
        <>
            <div className='landing'>
                <Navbar setPage={props.setPage}/>

                {/* <div className="topnav">
                    <div className='logo'>
                        <img className="responsive mt20 ml20" src={desktop_logo} />
                    </div>
                    <div className='links'>
                        <a>Home</a>
                        <a>Contact Us</a>
                        <a>T&C</a>
                        <a>Privacy policy</a>
                        <a>About Us</a>
                        <div className='login' onClick={(e)=>{props.setPage("login")}}>
                            <img src={desktop_login} />
                            <a className='btnlogin' >Login</a>
                        </div>
                    </div>
                </div> */}

                <div className='screen'>
                <img id='mainscreen' src={desktop} />
                </div>











                {/* <div className='flex'>
                    <div className='main'>
                        <p>DEFEND</p>
                        <p> INNOVATE</p>
                        <p> SUCCEED</p>
                        <div className='text'>

                            <text>Advanced cybersecurity and</text> <br /><text>software solutions for your</text><br />
                            <text>business success</text>
                        </div>
                    </div>
                    <div className='headerlog'>
                        <img src={header_logo} />
                    </div>
                </div>
                <div className='emptyblack'></div>
                <div className='centertext'>
                    <p>Protecting Your Business with Advanced</p><br /><p> Cybersecurity and Software Solutions</p>
                    <div className='text2'>
                        <text>Defend your digital assets and power your business with our integrated cybersecurity</text><br />
                        <text> and software solutions. From robust pentesting to agile development, we deliver</text><br />
                        <text> proactive protection and innovative solutions for your digital journey.</text>
                    </div>
                </div> */}
                {/* <div className='mainsvg'>
                    <img src={mainsvg} /> */}
                    {/* <div className='mainsvgtext'>
                    <p>Software</p>
                    <p>Development</p>
                    <div className='text3'>
                        <text>From concept to deployment, we deliver tailored</text><br />
                        <text>software development solutions to empower your</text><br />
                        <text>business with innovative and scalable applications.</text>
                    </div>
                </div> */}
                {/* </div> */}
                {/* <div className='line12'>
                <img src={line12} />
            </div>
            <div className='mainsvg2'>
                <div className='mainsvgtext2'>
                    <p>Bussiness</p><br />
                    <p>Process</p><br />
                    <p>Automation </p>
                </div>
                <img src={mainsvg2} />
            </div> */}
            </div>

        </>
    )

}

export default Landing;