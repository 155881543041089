import Login from './hdfc/login/login';
import Register from './hdfc/register/register';
import Home from './hdfc/home/home';
import Sub from './hdfc/sub/sub';
import Profile from './hdfc/profile/profile';
import Landing from './landing/landing';
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import About from './landing/compliance/about';
import Privacy from './landing/compliance/privacy';
import Tc from './landing/compliance/tc';
import Contact from './landing/compliance/contact';
import Refund from './landing/compliance/refund';


function Mainpage() {
    const [getPage, setPage] = useState("landing")
    const cookie = new Cookies()
    const check_cookie = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_URL}/cookie`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const data = await res.json()
            if (res.status === 200) {
                if (data.status === true) {
                    return
                } else {
                    setPage("login")
                }
            } else {
                setPage("login")
            }
        } catch (error) {
            setPage("login")
        }
    }
    useEffect(() => {
        // check_cookie()
    }, [])
    if (getPage === "landing") {
        return (
            <>
                <Landing setPage={setPage} />
            </>
        )
    } else if (getPage === "login") {
        return (
            <>
                <Login check_cookie={check_cookie} setPage={setPage} />
            </>
        )
    } else if (getPage === "register") {
        return (
            <>
                <Register setPage={setPage} />
            </>
        )
    } else if (getPage === "home") {
        return (
            <>
                <Home check_cookie={check_cookie} setPage={setPage} />
            </>
        )
    } else if (getPage === "subscription") {
        return (
            <>
                <Sub check_cookie={check_cookie} setPage={setPage} />
            </>
        )
    } else if (getPage === "profile") {
        return (
            <>
                <Profile check_cookie={check_cookie} setPage={setPage} />
            </>
        )
    } else if (getPage === "about") {
        return(
            <>
                <About setPage={setPage} />
            </>
        )
    }else if (getPage === "privacy") {
        return(
            <>
                <Privacy setPage={setPage} />
            </>
        )
    }else if (getPage === "tc") {
        return(
            <>
                <Tc setPage={setPage} />
            </>
        )
    }else if (getPage === "contact") {
        return(
            <>
                <Contact setPage={setPage} />
            </>
        )
    }else if (getPage === "refund") {
        return(
            <>
                <Refund setPage={setPage} />
            </>
        )
    }
}

export default Mainpage